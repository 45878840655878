<template>
  <div>
    <InfoHeader></InfoHeader>
    
    <div class="mb-20"></div>
     <div class="task-check">
      <div class="person-header flex-sb">
          <p>
            <a  download="w3logo" target="_blank" href="/mall">
            下载
            </a>
              <strong>工作成果验收</strong>
          </p>
          <span class="f-14 b" @click="goTaskInfo">
             返回
          </span>
      </div>
      <div class="mb-40"></div>
      <div class="task-checktabel flex">
          <div class="task-checktabel-left flex-sb flex-wp" v-show="true">
              <div class="task-checktabel-left-button flex-sb">
                  <el-button type="text" class="b">批量操作</el-button>
                  <el-button type="text" class="b">一键操作</el-button>
              </div>
              <div class="mb-20"></div>
              <div class="task-checktabel-left-tabel">
                <el-table ref="multipleTable" :data="checkTable" style="width: 200px; font-size: 12px"
                 border @selection-change="handleSelectionChange" key="one"
                 :header-cell-style="{ 'text-align': 'center',height: '32px', color: '#fff',backgroundColor:'#575757'}"
                 :cell-style="{ 'text-align': 'center', height: '32px', color: '#474747','background-color':'#f4f4f4'}" >
                <el-table-column ref="selection" type="selection" width="58" key="one1">
                </el-table-column>
                <el-table-column label="姓名" width="60" prop="name" key="name"></el-table-column>
                <el-table-column prop="hireState" label="状态" width="81" key="hireState">
                </el-table-column>
                </el-table>
                <div class="task-checktabel-left-tabel-add">
                    <el-button type="text">加载更多</el-button>
                </div>
              </div>
          </div>
          <div class="task-checktabel-right">
            <h2 class="f-18">雇员：刘小宝 <span class="ml-20 f-14 h-57">3人</span></h2>
            <!-- 未提交工作成果 暂时先隐藏  -->
            <div class="task-checktabel-right-no" v-show="false">
              <svg class="icon wh-64" aria-hidden="true">
                <use xlink:href="#icon-a-noinformation"></use>
              </svg>
              <div class="mb-40"></div>
              <span class="f-18 h-57">本雇员暂未提交工作成果</span>
            </div>
            <!-- 单个人 时的状态 -->
            <div class="task-checktabel-right-one">
              <p class="f-12 h-57">
                疫情冲击下的2020年-2022年中国经济形势与政策选择,疫情冲击下的2020年-2022年中国经济形势与政策选择,疫情冲击下的2020年-2022年中国经济形势与政策选择
                .疫情冲击下的2020年-2022年中国经济形势与政策选择,疫情冲击下的2020年-2022年中国经济形势与政策选择,疫情冲击下的2020年-2022年中国经济形势与政策选择.
              </p>
              <!-- 周期明细 -->
              <CheckManHour></CheckManHour>
              <div class="mb-40"></div>
              <!-- 交付文件清单 -->
              <div class="task-file">
                <div class="task-file-header">
                  <strong class="f-18">交付文件清单</strong>
                  <div class="mb-20"></div>
                  <el-table :data="fileTabel" style="width: 100%"
                    center class="checkmantab" border key="two"
                    :header-cell-style="{ 'text-align': 'center',height: '32px', color: '#000','font-size':'12px',background:'#f4f4f4'}"
                    :cell-style="{ 'text-align': 'center', height: '32px', color: '#474747','font-size':'12px'}" > >
                    <el-table-column label="序号" key="twoC1" type="index"></el-table-column>
                    <el-table-column prop="fileType" label="文件"  width="289">
                      <template slot-scope="{row}">
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-PNG"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-DOC"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-7Z"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-TXT"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-JPG"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-PPT"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-ZIP"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-XLS"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="false">
                          <use xlink:href="#icon-file-PDF"></use>
                        </svg>
                        <svg class="icon mr-5" aria-hidden="true" v-show="true">
                          <use xlink:href="#icon-file-RAR"></use>
                        </svg>
                        {{row.fileType.fileTitle}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="uploadDate" :show-overflow-tooltip="true" label="上传时间"></el-table-column>
                    <el-table-column prop="workDescription" label="操作">
                      <template slot-scope="{row}">
                        <el-button type="text" @click="dialogVisible = true">{{row.handle.look}}</el-button>
                        <el-button type="text">{{row.handle.downLoad}}</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 验收 -->
              <div class="checkMoney-tabel">
                <div class="checkMoney-tabel-header">
                  <strong class="f-18">验收</strong>
                  <div class="mb-20"></div>
                </div>
                <div class="mb-20"></div>
                <div class="checkMoney-tabel-input">
                  <span class="mr-40 f-18 h-57">合同报酬：4000元</span>
                  <p><span class="f-18 h-57">实付金额：</span>
                  <el-input v-if="showMoney" type="number" v-model.number.trim="netMoney" placeholder="请输入报酬" style="width:120px">
                    </el-input><span v-else class="f-18 h-57">{{netMoney}}</span>
                    <b class="f-14 h-57" v-if="showMoney">元</b>
                    <!-- 任务小于1的时候出现 -->
                    <span v-show="true" class="f-12 b" @click="afreshCheck" style="cursor:pointer">修改</span>
                    </p>
                    
                </div>
                <div class="checkMoney-tabel-remark">
                  <strong class="f-14 h-57">验收备注：</strong>
                  <div class="mb-10"></div>
                  <el-input type="textarea" maxlength="300" show-word-limit placeholder="请填写验收备注" v-if="showTextarea" v-model="textarea" class="input-60">
                  </el-input>
                  <span v-else class="f-12 h-57">{{textarea}}</span>
                </div>
                <div class="mb-20"></div>
                <div class="checkMoney-tabel-afresh">
                    <el-button type="text" class="b" v-if="showMoney==false||showTextarea==false"
                     @click="afreshCheck">重新验收</el-button>
                </div>
                <div class="checkMoney-tabel-button">
                  <div class="checkMoney-tabel-button-border flex-sb">
                     <el-button type="text" style="position:relative;left:-20px" @click="cancel">取消操作</el-button>
                     <el-button class="primary" @click="passCheck" v-if="showMoney==true||showTextarea==true">通过验收</el-button>
                     <el-button class="refuse" v-if="showMoney==true||showTextarea==true">拒绝通过</el-button>
                  </div>
                </div>
                <div class="mb-40"></div>
                <!-- 验收日志 -->
                <div class="checkMoney-tabel-blog">
                  <div class="checkMoney-tabel-blog-header bortom">
                    <strong class="f-18">验收日志</strong>
                    <div class="mb-20"></div>    
                  </div>
                  <div class="mb-20"></div>
                  <div class="taskCheck-timeLine" >
                  <el-timeline >
                      <el-timeline-item
                        v-for="(activity, index) in activities"
                      :key="index"
                      :timestamp="activity.remark">
                      {{activity.isCheck}} 
                      <span>{{activity.timestamp}}</span>
                      </el-timeline-item>
                    </el-timeline>
                </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
  <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <span>这是一段信息</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import InfoHeader from "@/views/layout/modules/hirer/infoHeader"
import CheckManHour from "@/views/layout/modules/hirer/checkManHour"
export default {
  components:{
    InfoHeader,
    CheckManHour
  },
  name:"taskCheck",
  data(){
    return{
      checkTable:[
        {
          name:"刘小宝",
          hireState:"未验收"
        },
         {
          name:"刘小宝",
          hireState:"未验收"
        },
         {
          name:"刘小宝",
          hireState:"未验收"
        },
         {
          name:"刘小宝",
          hireState:"未验收"
        },
        {
          name:"刘小宝",
          hireState:"未验收"
        },
         {
          name:"刘小宝",
          hireState:"未验收"
        },
         {
          name:"刘小宝",
          hireState:"未验收"
        },
      ],
      fileTabel:[
        {
          fileType:{
          fileTitle:"疫情冲击下的2020年-202...政策选择.pdf"  
          },
          uploadDate:"2022-03-24  13:24:35",
          handle:{
            look:"查看",
            downLoad:"下载"
          }
        },
        {
          fileType:{
          fileTitle:"疫情冲击下的2020年-202...政策选择.pdf"  
          },
          uploadDate:"2022-03-24  13:24:35",
          handle:{
            look:"查看",
            downLoad:"下载"
          }
        },
        {
          fileType:{
          fileTitle:"疫情冲击下的2020年-202...政策选择.pdf"  
          },
          uploadDate:"2022-03-24  13:24:35",
          handle:{
            look:"查看",
            downLoad:"下载"
          }
        },
        {
          fileType:{
          fileTitle:"疫情冲击下的2020年-202...政策选择.pdf"  
          },
          uploadDate:"2022-03-24  13:24:35",
          handle:{
            look:"查看",
            downLoad:"下载"
          }
        },
      ],
      netMoney:"",//实付报酬
      textarea:"",//备注信息
      activities: [{
          timestamp: '2022-12-12 11:11:11',
          isCheck:"验收不通过",
          remark:"无备注"
        }, {
          timestamp: '2022-12-12 11:11:11',
          isCheck:"验收不通过",
          remark:"备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。"
        }, {
          timestamp: '2022-12-12 11:11:11',
          isCheck:"验收不通过",
          remark:"备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。备注信息已验收备注信息。"
      }],
      showMoney:true, //显示实付报酬
      showTextarea:true, //显示备注信息
      dialogVisible: false, //查看弹框
      listData:[{
          name:"刘小宝",
          hireState:"未验收"
        },],   
    }
  },
  methods:{
     toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
            this.$refs.selection.style.backgroundColor='#00a2e6'
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      goTaskInfo(){
        this.$router.push({path:"/home/hirer/task/info"})
      },
      /* 通过验收 */
      passCheck(){
          this.showMoney=false;
          this.showTextarea= false
      },
      /* 重新验收 */
      afreshCheck(){
          this.showMoney = true;
          this.showTextarea = true
      },
      /* 关闭弹窗 */
       handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      /* 取消操作 */
      cancel(){

      }
  },
  created(){
    this.$nextTick(() => {
   /* this.listData.forEach(row => {
    if(this.checkTable.indexOf(row.name) >= 0){
      this.$refs.multipleTable.toggleRowSelection(row,true);
      
    }
  }) */
  for (let i = 0; i < this.checkTable.length; i++) {
     this.$refs.multipleTable.toggleRowSelection(this.checkTable[0])
   }
 })
  }
}
</script>

<style lang="less" scoped>
.task-check{
    background-color: #fff;
    //头部部分
    .person-header{
        span{
            position: relative;
            top: 40px;
            right: 40px;
            cursor: pointer;
        }
    }
    //底部根据不同状态显示的tabel
    .task-checktabel{
      width: 860px;
      margin: 0 auto;
        .task-checktabel-left{
          margin-right: 40px;
            width: 200px;
            height: 200px;
            /deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
              background-color: #00a2e6 !important;
              color: #fff !important;
            }
            .task-checktabel-left-button{
                width: 200px;
                margin-bottom: 20px;
                height: 14px;
            }
        }
        .task-checktabel-right{
          width: 100%;
          .task-checktabel-right-no{
            width: 200px;
            margin: 0 auto;
            margin-top: 60px;
            text-align: center;
          }
          .task-checktabel-right-one{
            width: 100%;
            margin-top: 15px;
            margin-right: -40px;
            p{
              line-height: 22px;
            }
          }
          //验收
          .checkMoney-tabel{
            margin-top: 40px;
            .checkMoney-tabel-header{
              border-bottom: 1px solid #b5b5b5;
            }
            .checkMoney-tabel-input{
              height: 32px;
              display: flex;
              align-items: center;
              b{
                position: relative;
                left: -20px;
              }
            }
            .checkMoney-tabel-remark{
              margin-top: 28px;
            }
            .checkMoney-tabel-afresh{
                text-align: center;
                /deep/span{
                    color: #00A2e6;
                }
            }
            .checkMoney-tabel-button{
              width: 100%;
              display: flex;
              justify-content: flex-end;
              .checkMoney-tabel-button-border{
                width: 260px;   
              }
            }
          }
          //验收日志
          .checkMoney-tabel-blog{
            .taskCheck-timeLine{
              padding-bottom: 30px;
             .taskCheckLi{
               line-height: 20px;
             }
              p{
                height: 15px;
                border: 1px solid red;
                position: relative;
                top: -6px;
                left: -20px;
                text-align: left;
                display: block;
              }
              /deep/.el-timeline-item__content{
                font-size: 12px;
                color: #575757;
                position: relative;
                top:3px;
              }
              
              /deep/.el-timeline-item__timestamp{
                font-size: 12px;
                color: #575757;
                position: relative;
                right: -80px;
                top: -19px;
                width: 380px;
                line-height: 16px;
              }
              span{
                position: relative;
                left: -215px;
              }
            }
          }
        }
    }
}
/deep/.el-table__header 
.el-table-column--selection 
.cell .el-checkbox:after {
  color: #fff;
  display: inline-block;
  content: "全选";
  font-size: 12px;
  margin-left: 5px;
}
/* 去除padding */
/deep/.el-button{
  padding: 0;
}
/deep/.el-table .el-table__cell{
  padding: 0;
}
/deep/input[placeholder]{
  font-size: 12px;
  color: #575757;
}
.primary{
  width: 120px;
  height: 24px;
  background-color: #00A2e6;
  border-radius: 0;
  color: #fff;
  font-size: 12px;
}
.refuse{
  width: 120px;
  height: 24px;
  background-color: #fff;
  border-radius: 0;
  border-color: #00A2e6;
  color: #00A2e6;
  font-size: 12px;
}
/deep/.el-timeline-item {
 padding-bottom: 0;
}
/deep/.taskCheck-timeLine{
  margin-left: 125px;
}
/deep/.el-button--text{
  color: #575757;
}
/deep/.el-button--text:hover{
  color: #00a2e6;
}
/* 左侧工作成果验收 加载更多 */
.task-checktabel-left-tabel{
  /deep/.el-table .cell{
     text-overflow:clip ;
     overflow: none;
    }
}
/deep/.task-checktabel-left-tabel-add{
    background-color: #f4f4f4;
    width: 200px;
    line-height: 32px;
    margin-top: 2px;
    text-align: center;
    color: #00A2e6 !important;
    
}
.input-60{
    /deep/.el-textarea__inner{
      height: 80px;  
    }
}
</style>